import React from 'react';
import guruji from "../assets/guruji.jpeg";


function Navbar() {
    return (
        <nav class="navbar bg-light" style={{ textAlign: "center", justifyContent: "center" }}>
            <div>
                <a class="navbar-brand header-title" href="/"><img src={guruji} style={{ borderRadius: "50%", height: "80px", margin: "0 12px 0 9px", width: "80px" }} /><b style={{ fontSize: "28px", textDecoration: "underline" }}>GKAMM</b></a>
            </div>
        </nav>
    )
}

export default Navbar