import Navbar from "./components/Navbar";
import gurujiPhoto from "./assets/gurujiPhoto.jpeg"

export default function Home() {

  return (
    <div className='body'>
      <Navbar />
      <div>
        <h1 style={{ textAlign: "center", fontWeight: "bold", marginTop: "30px", marginBottom: "17px" }}>
          Jai Guruji 🙏🏻
        </h1>

        <img src={gurujiPhoto} className="img-thumbnail" style={{ display: "block",marginLeft:"auto",marginRight:"auto", maxHeight: "600px" }} />
      </div>
    </div>
  )
}
